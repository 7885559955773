<template>
  <lidaPage
  ref="lidaPage"
    :columns="columns"
    :query="purchaseReq"
    :formColumns="formColumns"
    :queryInfo="queryInfo"
    subTitle="总采购单数"
    :tableRowClassName="tableRowClassName"
  >
  <template #inquiryNo="scope">
      <!-- <div>{{scope.row.inquiryNo}}{{scope.row.undoApplyUserId==userId?}}</div> -->
      <div>
        <span>{{ scope.row.inquiryNo }}</span>
        <el-tag
          style="margin-left:10px"
          size="mini"
          v-if="scope.row.undoAudit === 'IN_PASS'"
          >{{
            scope.row.undoAudit == "IN_PASS"
              ? scope.row.undoApplyUserId == userId
                ? "作废待审核"
                : "作废申请中"
              : scope.row.undoAudit == "PASS"
              ? "同意(买方审核) "
              : scope.row.undoAudit == "UN_PASS"
              ? "不同意 (买方审核)"
              : ""
          }}</el-tag
        >
      </div>
    </template>
    <template #operation>
      <lida-table-column label="开发票情况">
        <template #default="scope">
          <span>{{
            scope.row.invoiceStatus == "0" ? "未开票" : "已开票"
          }}</span>
        </template>
      </lida-table-column>
      <lida-table-column label="付款比例">
        <template #default="scope">
          <span
            >{{
              (scope.row.paidAmount / scope.row.totalAmount) * 100
            }}%</span
          >
        </template>
      </lida-table-column>
      <lida-table-column label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button
            type="text"
            @click="commands(scope.row)"
            style="color: #D84146;cursor: pointer;"
            >查看</el-button
          >
           <el-button
            type="text"
            v-if="scope.row.undoFlag=='0'"
            @click="remove(scope.row)"
            style="color: #D84146;cursor: pointer;"
            >作废</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.undoFlag=='1'"
            @click="deleteRow(scope.row)"
            style="color: #D84146;cursor: pointer;"
            >删除</el-button
          >
        </template>
      </lida-table-column>
    </template>
  </lidaPage>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("purchaseMudule", [
      "documentState",
      "documentStateDel",
      "invoiceStatusText",
      
    ]),
  },
  data() {
    return {
      userId: JSON.parse(localStorage.getItem("userInfo")).userId,
      columns: [
        { label: "询价单号", prop: "inquiryNo", width: 200 },
        { label: "采购单号", prop: "documentNumber", width: 180 },
        { label: "供应商公司名称", prop: "sellerEnterpriseName", width: 210 }, //未知
        { label: "供应商联系人", prop: "sellerName" }, //未知
        { label: "供应商手机号", prop: "sellerPhone", width: 130 }, //未知
        { label: "客户公司名称", prop: "inquiryEnterpriseName", width: 180 }, //未知
        { label: "最终用户", prop: "inquiryCustomerName" },
        { label: "采购品牌", prop: "purchaseBrands", width: 210 }, //未知
        { label: "采购数量", prop: "purchaseQty" }, //未知
        { label: "采购总金额", prop: "totalAmount" }, //未知
        { label: "付款金额", prop: "paidAmount" }, //未知
        // {label: '付款比例',prop: ''},  //未知
        // {label: '发货单号',prop: 'courierNumber'},  //未知
        // {label: '开发票情况',prop: 'invoiceStatus'},  //未知
      ],
      formColumns: [
        { type: "date", prop: "time", label: "采购日期" },
        { type: "input", prop: "wd", label: "综合搜索" },
         {
          type: "select",
          prop: "documentStatus",
          label: "单据状态",
          options: [
            {
              value: "WAIT_QUOTE",
              label: "待报价",
            },
            {
              value: "COMPLETE_QUOTE",
              label: "已报价",
            },
            {
              value: "REVOKE",
              label: "已撤销",
            },
            ],
         }
      ],
      queryInfo: { type: "0" },
    };
  },
  watch: {},
  methods: {
    ...mapActions("purchaseMudule", ["purchaseReq","purchaseDelete",'purchaseUndo']),
    commands(v) {
      // this.$router.push({
      // 	path:'/purchase/purchaseDetails',
      // 	query:{
      // 		id:v.documentId,  //订单id
      // 		inquiryId:v.inquiryId,  //询价单id
      // 	}
      // })
      this.$router.push({
        path: "/mysale/xinOrderDetails",
        query: {
          id: v.documentId, //订单id
          inquiryId: v.inquiryId, //询价单id
          type:'_purchase',
        },
      });
    },
    tableRowClassName({row, rowIndex}) {
		if(row.undoFlag=='1'){
			 return 'warning-row';
		}
        return '';
      },
    getText(type) {
      return this.identifyLabel.filter((v) => v.type === type).length
        ? this.identifyLabel.filter((v) => v.type === type)[0].title
        : "";
    },
    remove(v){
this.purchaseUndo(v.documentId).then(res=>{
  if (res&&res.status==200) {
    v.disabled=true
			 this.$message({
          message: '作废成功',
          type: 'success'
        });
         this.$refs.getData()
			// window.location.reload();
		}else{
			this.$message.error('作废失败');
		}
      });
    },
    deleteRow(v) {
      this.purchaseDelete(v.documentId).then((res) => {
        // console.log(res);
		if (res.code=='0') {
			 this.$message({
          message: '删除成功',
          type: 'success'
        });
         this.$refs.getData()
			
		}else{
			this.$message.error('删除失败');
		}
      });
    },
  },
};
</script>
